import React, { useState } from "react";
import { Switch, FormControlLabel, TextField, Button } from "@mui/material";
import "./style/Api.css"; // Import the external CSS file
import { styled } from "@mui/material/styles";

const Api = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = isLogin
      ? "http://hackithonapi.node.cloud.bagros.eu/auth/login"
      : "http://hackithonapi.node.cloud.bagros.eu/auth/register";

    const headers = {
      "accept": "application/json",
      "Content-Type": isLogin ? "application/x-www-form-urlencoded" : "application/json",
    };

    const body = isLogin
      ? `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
      : JSON.stringify({
          email: username,
          password,
        });

    const requestOptions = {
      method: "POST",
      headers,
      body,
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      setApiResponse(data);
      // Handle the API response as needed
    } catch (error) {
      console.error("API Error:", error);
      // Handle API error
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ffff",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#515151",
    },
  }));

  return (
    <div className="api-container">
      <div className="api">
        <div className="api-switcher">
          <p className={isLogin ? "api-active" : ""}>Přihlásit</p>
          <FormControlLabel
            control={
              <PinkSwitch
                checked={!isLogin}
                onChange={() => setIsLogin(!isLogin)}
              />
            }
            className={`switch-label ${isLogin ? "" : "active"}`}
          />
          <p className={!isLogin ? "api-active" : ""}>Registrovat</p>
        </div>

        <form onSubmit={handleSubmit} className="form-container">
          <TextField
            label={isLogin ? "Uživatelské jméno" : "Email"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
            required
            className="text-field"
          />

          <TextField
            label="Heslo"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
            className="text-field"
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={`submit-button ${isLogin ? "login" : ""}`}
            style={{ backgroundColor: "#ff4757" }}
          >
            {isLogin ? "Přihlásit" : "Registrovat"}
          </Button>
        </form>

        {apiResponse && (
          <div className="api-response">
            <p>API Response:</p>
            <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default Api;
