import { Outlet, NavLink } from "react-router-dom";
import React from "react";
import "./style/Layout.css";

const Layout = () => {
  return (
    <div className="layout">
      <nav>
        <ul>
          <li>
            <NavLink to="/" activeClassName="active" exact>Domů</NavLink>
          </li>
          <li>
            <NavLink to="/api" activeClassName="active">API</NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active">O aplikaci</NavLink>
          </li>
        </ul>
      </nav>
      <div className="layout-content">
        <Outlet />
      </div>
    </div>
  )
};

export default Layout;
