import React, { useState } from "react";
import Map from "./components/Map";
import AutoCompleteSearch from "./components/AutoCompleteSearch";
import Chart from "./components/chart";
import "./style/Home.css";


const Home = () => {
  const [polygonsData, setPolygonsData] = useState([]);

  const handleRemovePolygon = (indexToRemove) => {
    const updatedPolygonsData = polygonsData.filter(
      (_, index) => index !== indexToRemove
    );
    setPolygonsData(updatedPolygonsData);
  };

  return (
    <>
      <div className="home-map">
        <div className="home-Search">
          <AutoCompleteSearch setPolygonsData={setPolygonsData} />
        </div>
        <div className="home-seznam">
          {polygonsData.map((polygon, index) => (
            <>
              <div key={index} className="polygon-item">
                <span>{polygon.NAZEV}</span>
                <span>{polygon.KOD}</span>
                <button onClick={() => handleRemovePolygon(index)}>
                  Smazat
                </button>
              </div>
              <div className="home-info">
                <p>Počet zemřelých: {Math.floor(Math.random() * 100)}</p>
                <p>Počet narozených: {Math.floor(Math.random() * 100)}</p>
              </div>
              <div className="home-chart">
                <Chart />
              </div>
            </>
          ))}
        </div>
        <Map polygonsData={polygonsData} setPolygonsData={setPolygonsData} />
      </div>
    </>
  );
};

export default Home;
