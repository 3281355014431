import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import { GridLoader } from "react-spinners";
import "leaflet/dist/leaflet.css";
import "./style/Map.css";

const Map = ({setPolygonsData, polygonsData}) => {
  const [geojsonData, setGeojsonData] = useState(null);
   // Use an array for multiple polygons
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch("/files/output_2.json")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setPolygonsData(data);
        } else {
          console.error("Data should be an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching output_2.json data:", error);
      });

    fetch("/files/czech-republic-with-regions.json")
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.features)) {
          setGeojsonData(data.features);
        } else {
          console.error("Invalid or missing 'features' in geojson data:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching geojson data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const position = [49.8, 15.5];

  if (isLoading) {
    return <GridLoader color="#ff4757" className="map-loading" />;
  }

  return (
    <MapContainer
      center={position}
      zoom={8}
      style={{ width: '100%', height: '100vh' }}
      className="map"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {geojsonData && geojsonData.map((feature, index) => {
        if (feature.geometry.type === "MultiPolygon") {
          return feature.geometry.coordinates.map((polygon, polyIndex) => (
            <Polygon
              key={`${index}-${polyIndex}`}
              positions={polygon[0].map((coord) => [coord[1], coord[0]])}
              color="#ff4757"
            />
          ));
        }
        return null;
      })}

      {/* Render all polygons from polygonsData */}
      {polygonsData.map((polygon, index) => {
        const coords = polygon.geometry
          .replace("POLYGON ((", "")
          .replace("))", "")
          .split(", ")
          .map(coord => {
            const [lon, lat] = coord.split(" ").map(Number);
            return [lat, lon];
          });
        return (
          <Polygon
            key={index}
            positions={coords}
            color="#0000ff" // Different color for polygons from output_2.json
          />
        );
      })}

    </MapContainer>
  );
};

export default Map;
