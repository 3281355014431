import React, { useState } from "react";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const AutoCompleteSearch = ({ setPolygonsData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    // Zde provádíme GET požadavek na první API pro hledání obcí
    axios
      .get(
        `https://hackithonapi.node.cloud.bagros.eu/sql/search/${event.target.value}/`
      )
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectionChange = (event, value) => {
    if (value) {
      // Zde provádíme GET požadavek na druhé API s vybraným názvem obce
      axios
        .get(`https://hackithonapi.node.cloud.bagros.eu/sql/${value}/`)
        .then((response) => {
          // Zde můžete zpracovat odpověď druhého API
          console.log(response.data);
          setPolygonsData((prevData) => [...prevData, response.data]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Autocomplete
      id="search-input"
      options={options}
      getOptionLabel={(option) => option}
      style={{ width: 300, outline: "#ff4757" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Najdi obec"
          variant="outlined"
          onChange={handleInputChange}
        />
      )}
      onChange={handleSelectionChange}
    />
  );
};

export default AutoCompleteSearch;
