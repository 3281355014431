import { Link } from "react-router-dom";
import React from "react";
import "./style/NoPage.css";

const NoPage = () => {
  return (
    <div className="nopage">
      <h1>Chyba 404</h1>
      <p>Stránka nenalezena</p>
      <Link to="/">Zpět na hlavní stránku</Link>
    </div>
  );
};

export default NoPage;
