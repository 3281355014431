import React from "react";
import "./style/About.css";

const About = () => {
  const logos = [
    "../files/images/img1.jpg",
    "../files/images/img6.jpg",
    "../files/images/img7.jpg",
    "../files/images/img2.jpg",
    "../files/images/img3.jpg",
  ];

  const text = (

    <div className="about-text">
      <h2>O APLIKACI</h2>
      <div className="about-functions">
        <h3>Funkce</h3>
        <p>
          Aplikace přijímá data z datových souborů typu csv týkajících se
          správních celků ČR (základní územní jednotky, obce, okresy...) a
          přehledně je zobrazuje na mapu. Je užitečná pro analytiky dat a obecně
          pro každého, kdo touží po srozumitelné vizualizaci vybraných
          informací.
        </p>
        <p>
          Důležité je, aby soubor obsahoval validní identifikátor územního
          celku, bez nějž by vykreslení na mapu nebylo možné.
        </p>
      </div>
      <div className="aboutt">
        <div className="about-technology">
          <h3>Technologie</h3>
          <p>Backend:</p>
          <ul>
            <li>Pandas</li>
            <li>FastAPI</li>
            <li>MariaDB</li>
          </ul>
          <p>Frontend:</p>
          <ul>
            <li>React</li>
          </ul>
          <p>Kontejnerizace:</p>
          <ul>
            <li>Docker</li>
          </ul>
        </div>
        <div className="about-developers">
          <h3>Vývojáři</h3>
          <ul>
          <li>Rozálie Rybová</li>
            <li>Marek Tremel</li>
            <li>Ladislav Pokorný</li>
            <li>Matěj Verner</li>
            <li>Tomáš Kroupa</li>
          </ul>
        </div>
      </div>
    </div>
 
  );

  return (
    <div className="about-contak">
    <div className="about-container">
      {text}
      <div className="about-gallery">
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`Logo ${index + 1}`} />
        ))}
      </div>
    </div>
    </div>
  );
};

export default About;
